import React, { FC } from 'react';
import { LiveSessionMessage } from './LiveView';
import clsx from 'clsx';
import { timestampToHM } from '../../utils/formatTime';
import { IconButton } from '@mui/material';
import editIcon from '@iconify/icons-eva/edit-outline';
import trashIcon from '@iconify/icons-eva/trash-outline';
import { Icon } from '@iconify/react';

interface LiveMessageProps {
  message: LiveSessionMessage;
  onEdit: (id: any) => void;
  onDelete: (id: any) => void;
}

const LiveMessage: FC<LiveMessageProps> = ({ message, onEdit, onDelete }) => (
  <div
    className={clsx(['live-message', message.flag && `with-icon type-${message.flag}`])}
    key={message.messageId}
  >
    <div className="live-message-meta">
      <div className="live-message-icon">
        <span className={'message-icon'} />
      </div>
      <div className="live-message-time">
        <span className={'message-time'}>{timestampToHM(message.created)}</span>
      </div>
    </div>
    <div
      className={'live-message-content'}
      dangerouslySetInnerHTML={{
        __html: message?.message?.replace(/<img/gi, '<img loading="lazy"') ?? ''
      }}
      key={message.id}
    />
    <div className={'live-message-controls'}>
      <IconButton size={'small'} color={'primary'} onClick={() => onEdit(message.id)}>
        <Icon icon={editIcon} width={16} height={16} />
      </IconButton>
      <IconButton size={'small'} color={'error'} onClick={() => onDelete(message.id)}>
        <Icon icon={trashIcon} width={16} height={16} />
      </IconButton>
    </div>
  </div>
);

export default React.memo(LiveMessage);
