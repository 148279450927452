import React from 'react';
import LiveList from './LiveList';
import { Navigate, Route, Routes } from 'react-router-dom';
import LiveView from './LiveView';

const LiveRouter = () => (
  <Routes>
    <Route path="/" element={<LiveList />} />
    <Route path="list" element={<LiveList />} />
    <Route path="view/:nid" element={<LiveView />} />
    <Route path="*" element={<Navigate to="/404" />} />
  </Routes>
);

export default LiveRouter;
