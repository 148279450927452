import React, { createContext, FC, useContext, useEffect, useRef, useState } from 'react';
import { useAuth } from './useAuth';
import Centrifuge from 'centrifuge';

interface CentrifugeContext {
  centrifuge: Centrifuge | undefined;
  connected: boolean;
}

const centrifugeContext = createContext<CentrifugeContext>({
  centrifuge: undefined,
  connected: false
});

export const CentrifugeProvider: FC = ({ children }) => {
  const centrifuge = useCentrifugeProvider();
  return <centrifugeContext.Provider value={centrifuge}>{children}</centrifugeContext.Provider>;
};

export const useCentrifuge = () => {
  return useContext(centrifugeContext);
};

function useCentrifugeProvider() {
  const centrifuge = useRef<Centrifuge | undefined>(undefined);
  const [token, setToken] = useState();
  const [connected, setConnected] = useState(false);

  const { user, Api } = useAuth();

  useEffect(() => {
    if (token) {
      if (!centrifuge.current) {
        centrifuge.current = new Centrifuge('wss://autosport.com.ru/live/connection/websocket');
        centrifuge.current?.on('connect', () => {
          setConnected(true);
        });
        centrifuge.current?.on('disconnect', () => {
          setConnected(false);
        });
      }

      if (!centrifuge.current?.isConnected()) {
        centrifuge.current?.setToken(token);
        centrifuge.current?.connect();
      }
    } else {
      if (centrifuge.current && centrifuge.current?.isConnected()) {
        centrifuge.current?.disconnect();
      }
    }
  }, [token]);

  useEffect(() => {
    if (user) {
      if (!token) {
        Api?.get('/api/centrifugo/user').then((response) => {
          setToken(response.data.token);
        });
      }
    } else {
      if (token) {
        setToken(undefined);
      }
    }
  }, [token, user, Api]);

  useEffect(() => {
    return () => {
      if (centrifuge.current?.isConnected()) {
        centrifuge.current?.disconnect();
      }
    };
  }, []);

  return {
    centrifuge: centrifuge.current,
    connected
  };
}
