import React, { FC, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface AddImageDialogProps {
  open: boolean;
  onSave: (url: string, altText?: string) => void;
  onClose: () => void;
}

const AddImageDialog: FC<AddImageDialogProps> = ({ open, onSave, onClose }) => {
  const [url, setUrl] = useState<string>('');
  const [alt, setAlt] = useState<string>('');

  const handleCLose = useCallback(() => {
    setUrl('');
    setAlt('');
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleSave = useCallback(() => {
    if (onSave) {
      onSave(url, alt);
    }
    handleCLose();
  }, [url, alt, onSave, handleCLose]);

  return (
    <Dialog open={open} onClose={handleCLose} maxWidth={'sm'} fullWidth={true} keepMounted={false}>
      <DialogTitle title={'Разбор таблицы'}>
        Вставить изображение
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
          onClick={handleCLose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ py: 1 }}>
          <TextField
            fullWidth={true}
            label={'Адрес изображения'}
            placeholder={'https://example.domain.com/image.png'}
            value={url}
            size={'small'}
            onChange={(e) => setUrl(e.target.value)}
          />
        </Box>
        <Box sx={{ py: 1 }}>
          <TextField
            fullWidth={true}
            label={'Alt текст'}
            value={alt}
            size={'small'}
            placeholder={'Alt Text'}
            onChange={(e) => setAlt(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color={'inherit'} onClick={handleCLose}>
          Отмена
        </Button>
        <Button color={'primary'} onClick={handleSave}>
          Вставить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddImageDialog;
