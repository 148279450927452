import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Drawer, IconButton } from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import sidebarConfig from './SidebarConfig';

import { Icon } from '@iconify/react';
import ChevronLeftIcon from '@iconify/icons-ic/baseline-chevron-left';
import ChevronRightIcon from '@iconify/icons-ic/baseline-chevron-right';
import { DRAWER_COLLAPSED_WIDTH, DRAWER_WIDTH } from './config';
import AccountPopover from './AccountPopover';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: 'auto'
  }
}));

const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  },
  '& .MuiListItemText-root': {
    display: 'none'
  }
});

const SidebarDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  isCollapsed: PropTypes.bool,
  onCollapse: PropTypes.func
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  isCollapsed = false,
  onCollapse
}) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      {/*<Collapse*/}
      {/*  orientation="horizontal"*/}
      {/*  in={true}*/}
      {/*  collapsedSize={64}*/}
      {/*  sx={{ flexGrow: 1, width: '100%' }}*/}
      {/*>*/}
      <Box
        sx={{
          px: 2.5,
          py: 3,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <AccountPopover />
        {/*<Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>*/}
        {/*  <Logo />*/}
        {/*</Box>*/}
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }}></Box>

      {/*</Collapse>*/}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <SidebarDrawer
          open={!isCollapsed}
          variant={'permanent'}
          PaperProps={{
            sx: {
              width: isCollapsed ? DRAWER_COLLAPSED_WIDTH : DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
          <Box sx={{ py: 3, px: 1.5 }}>
            <IconButton onClick={onCollapse}>
              <Icon icon={isCollapsed ? ChevronRightIcon : ChevronLeftIcon} />
            </IconButton>
          </Box>
        </SidebarDrawer>
      </MHidden>
    </RootStyle>
  );
}
