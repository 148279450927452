import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
// import Register from './pages/Register';
// import DashboardApp from './pages/DashboardApp';
// import Products from './pages/Products';
// import Blog from './pages/Blog';
// import User from './pages/User';
import NotFound from './pages/Page404';
import { useAuth } from './hooks/useAuth';
import LiveRouter from './pages/Live/LiveRouter';
// ----------------------------------------------------------------------

export default function Router() {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return (
      <Routes>
        <Route path="/" element={<LogoOnlyLayout />}>
          <Route path="" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route
            path="*"
            element={<Navigate to={`/login?return=${encodeURIComponent(location.pathname)}`} />}
          />
        </Route>
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/dashboard" element={<DashboardLayout />}>
        <Route path="" element={<Navigate to="/dashboard/live" replace />} />
        <Route path="live/*" element={<LiveRouter />} />
        {/*
        <Route path="app" element={<DashboardApp />} />
        <Route path="user" element={<User />} />
        <Route path="products" element={<Products />} />
        <Route path="blog" element={<Blog />} />
        */}
      </Route>
      <Route path="/" element={<LogoOnlyLayout />}>
        <Route path="login" element={<Login />} />

        {/* <Route path="register" element={<Register />} /> */}
        <Route path="404" element={<NotFound />} />
        <Route path="/" element={<Navigate to="/dashboard/live" />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );

  // return useRoutes([
  //   {
  //     path: '/dashboard',
  //     element: <DashboardLayout />,
  //     children: [
  //       { element: <Navigate to="/dashboard/app" replace /> },
  //       { path: 'app', element: <DashboardApp /> },
  //       { path: 'user', element: <User /> },
  //       { path: 'products', element: <Products /> },
  //       { path: 'blog', element: <Blog /> }
  //     ]
  //   },
  //   {
  //     path: '/',
  //     element: <LogoOnlyLayout />,
  //     children: [
  //       { path: 'login', element: <Login /> },
  //       { path: 'register', element: <Register /> },
  //       { path: '404', element: <NotFound /> },
  //       { path: '/', element: <Navigate to="/dashboard" /> },
  //       { path: '*', element: <Navigate to="/404" /> }
  //     ]
  //   },
  //   { path: '*', element: <Navigate to="/404" replace /> }
  // ]);
}
