import React, { FC, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TranslateIcon from '@mui/icons-material/Translate';
import { AxiosInstance } from 'axios';

interface TableParserProps {
  open: boolean;
  onClose: () => void;
  onSave: (table: string) => void;
  api?: AxiosInstance;
  onLoading?: (loading: boolean) => void;
}

type ColAlign = 'inherit' | 'right' | 'left' | 'center' | undefined;

const TableParser: FC<TableParserProps> = ({ onSave, onLoading, api, open, onClose }) => {
  const [tableRows, setTableRows] = useState<string[][]>([]);
  const [tableHead, setTableHead] = useState<string[]>([]);
  const [colAlign, setColAlign] = useState<ColAlign[]>([]);
  const [input, setInput] = useState<string>('');
  const [parsed, setParsed] = useState<boolean>(false);
  const [handleHeaders, setHandleHeaders] = useState<boolean>(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuCol, setMenuCol] = React.useState<number | undefined>(undefined);

  const parseTable = useCallback(() => {
    const result = [];
    const _rows = input.toString().trim().split('\n');
    let colsCount = 0;
    for (const row of _rows) {
      const cols = row.replace(/\s{4}/g, '\t').split(/\t/);
      const _row: string[] = [];
      for (const col of cols) {
        _row.push(col?.trim());
      }
      colsCount = Math.max(_row.length, colsCount);
      result.push(_row);
    }

    for (let resultRow of result) {
      if (resultRow.length < colsCount) {
        const oldLength = resultRow.length;
        resultRow.length = colsCount;
        resultRow.fill('', oldLength, colsCount);
      }
    }

    const [head, ...data] = result;
    if (handleHeaders) {
      setTableHead(head);
      setTableRows(data);
    } else {
      setTableHead(new Array(colsCount).fill('Столбец'));
      setTableRows(result);
    }
    setColAlign(new Array(colsCount).fill('inherit'));
    setParsed(true);
  }, [input, handleHeaders]);

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
    setMenuCol(event.currentTarget.value);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuCol(undefined);
  };

  const handleSave = useCallback(() => {
    const widths = new Array(tableHead.length).fill(0);
    const lines = [];

    for (const row of [tableHead, ...tableRows]) {
      for (let i = 0; i < row.length; i++) {
        widths[i] = Math.max(widths[i], row[i].toString().length);
      }
    }

    lines.push(
      '| ' +
        tableHead.map((th, thindex) => th.toString().padEnd(widths[thindex])).join(' | ') +
        ' |'
    );

    lines.push(
      '| ' +
        colAlign
          .map((ca, caindex) => {
            if (ca === 'left') {
              return ':-'.padEnd(widths[caindex], '-');
            } else if (ca === 'right') {
              return '-:'.padStart(widths[caindex], '-');
            } else if (ca === 'center') {
              return ':-'.padEnd(widths[caindex] - 1, '-') + ':';
            } else {
              return '-'.padEnd(widths[caindex], '-');
            }
          })
          .join(' | ') +
        ' |'
    );

    for (const tableRow of tableRows) {
      lines.push(
        '| ' +
          tableRow.map((td, tdindex) => td.toString().padEnd(widths[tdindex])).join(' | ') +
          ' |'
      );
    }

    if (onSave) {
      onSave(lines.join('\n'));
    }
    setParsed(false);
    setInput('');
  }, [tableRows, tableHead, colAlign]);

  const handleAlignCol = useCallback(
    (align: ColAlign) => {
      setColAlign((prev) => {
        const newAlign = [...prev];
        if (menuCol) {
          newAlign[menuCol] = align;
        }
        return newAlign;
      });
      setAnchorEl(null);
      setMenuCol(undefined);
    },
    [menuCol]
  );

  const handleRemoveRow = useCallback(
    (id) => {
      const newRows = [...tableRows];
      newRows.splice(id, 1);
      setTableRows(newRows);
    },
    [tableRows]
  );

  const handleRemoveCol = useCallback(() => {
    if (menuCol) {
      const newRows = [...tableRows];
      const newHead = [...tableHead];
      const newAlign = [...colAlign];

      for (const newRow of newRows) {
        newRow.splice(menuCol, 1);
      }
      newHead.splice(menuCol, 1);
      newAlign.splice(menuCol, 1);
      setTableRows(newRows);
      setTableHead(newHead);
      setColAlign(newAlign);
    }
    setAnchorEl(null);
    setMenuCol(undefined);
  }, [menuCol, tableRows, tableHead, colAlign]);

  const postRequest = useCallback(
    (url, data) => {
      if (onLoading) {
        onLoading(true);
      }
      if (api) {
        return api?.post(url, data).finally(() => {
          if (onLoading) {
            onLoading(false);
          }
        });
      }
    },
    [api, onLoading]
  );

  const handleTranslateRow = useCallback(() => {
    if (menuCol) {
      const request = tableRows.map((tr) => tr[menuCol] ?? '');
      postRequest('/api/v1/translate', request ?? [])?.then((response) => {
        if (response.data.cells) {
          const newRowData = [...tableRows];
          for (let i = 0; i < newRowData.length; i++) {
            newRowData[i][menuCol] = response.data.cells[i]?.translated ?? '';
          }
          setTableRows(newRowData);
        }
      });
      setAnchorEl(null);
      setMenuCol(undefined);
    }
  }, [menuCol, tableRows, postRequest]);

  return (
    <Dialog open={open} onClose={onClose} keepMounted={false} fullWidth={true} maxWidth={'md'}>
      <DialogTitle title={'Разбор таблицы'}>
        Разбор таблицы
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Box>
          {parsed ? (
            <div>
              <TableContainer component={Paper}>
                <Table
                  sx={{
                    '& td, & th': {
                      border: '1px solid rgba(241, 243, 244, 1)',
                      borderCollapse: 'collapse'
                    },
                    '& th': {
                      paddingRight: 4,
                      position: 'relative'
                    },
                    '& th .col-menu': {
                      position: 'absolute',
                      right: 5
                    }
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width={16} />
                      {tableHead.map((th, thindex) => (
                        <TableCell key={`th-${thindex}`} align={colAlign[thindex]}>
                          {th}
                          <IconButton
                            className={'col-menu'}
                            size={'small'}
                            onClick={handleMenuOpen}
                            value={thindex}
                          >
                            <MenuIcon fontSize={'inherit'} />
                          </IconButton>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableRows.map((tr, trindex) => (
                      <TableRow key={`tr-${trindex}`}>
                        <TableCell>
                          <IconButton onClick={() => handleRemoveRow(trindex)}>
                            <DeleteForeverIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                        {tr.map((td, tdindex) => (
                          <TableCell align={colAlign[tdindex]} key={`tr-${trindex}-td-${tdindex}`}>
                            {td}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          ) : (
            <>
              <div>
                <FormGroup sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={handleHeaders}
                        color={'primary'}
                        size={'small'}
                        onChange={(event) => setHandleHeaders(event.target.checked)}
                      />
                    }
                    label="Определять заголовки столбцов"
                  />
                </FormGroup>
                <TextField
                  fullWidth
                  onChange={(e) => setInput(e.target.value)}
                  multiline={true}
                  minRows={10}
                  maxRows={15}
                />
              </div>
            </>
          )}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button color={'inherit'} onClick={onClose}>
          Отмена
        </Button>
        {!parsed ? (
          <Button color={'primary'} onClick={parseTable}>
            Разобрать
          </Button>
        ) : (
          <Button color={'primary'} onClick={handleSave}>
            Вставить
          </Button>
        )}
      </DialogActions>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuList>
          <MenuItem onClick={() => handleAlignCol('left')}>
            <ListItemIcon>
              <FormatAlignLeftIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Выровнять по левому краю</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleAlignCol('right')}>
            <ListItemIcon>
              <FormatAlignRightIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Выровнять по правому краю</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleAlignCol('center')}>
            <ListItemIcon>
              <FormatAlignCenterIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Выровнять по центру</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleRemoveCol}>
            <ListItemIcon>
              <DeleteForeverIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Удалить столбец</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleTranslateRow}>
            <ListItemIcon>
              <TranslateIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Перевести столбец</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </Dialog>
  );
};

export default TableParser;
