import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import Page from '../../components/Page';
import {
  Backdrop,
  Card,
  CircularProgress,
  Container,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Scrollbar from '../../components/Scrollbar';
import { alpha } from '@mui/material/styles';

interface PaginationState {
  limit: number;
  offset: number;
  count: number;
  total: number;
  search?: string;
}

interface LiveSessionAuthor {
  uid: number;
  username: string;
}

export interface LiveSession {
  nid: number;
  vid: number;
  type: string;
  language: string;
  title: string;
  uid: number;
  status: number;
  created: string;
  changed: string;
  comment: number;
  promote: number;
  author: LiveSessionAuthor;
}

const initialPagination: PaginationState = {
  limit: 25,
  offset: 0,
  count: 0,
  total: 0,
  search: ''
};

const LiveList = () => {
  const { user, Api } = useAuth();
  const [sessions, setSessions] = useState<LiveSession[]>([]);
  const [pagination, setPagination] = useState<PaginationState>(initialPagination);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchSessions = useCallback(
    (fetchParams: Partial<PaginationState> = {}) => {
      const params = new URLSearchParams();
      const _pagination = { ...pagination, ...fetchParams };
      for (const [key, value] of Object.entries(_pagination)) {
        if (value && !['count', 'total'].includes(key)) {
          params.append(key, value.toString());
        }
      }
      setLoading(true);
      Api?.get(`/api/v1/sessions?${params.toString()}`)
        .then((response) => {
          const { result, ...paginationResponse } = response.data;
          setSessions(result);
          setPagination(paginationResponse);
        })
        .finally(() => setLoading(false));
    },
    [pagination, Api]
  );

  useEffect(() => {
    if (user && !sessions.length) {
      fetchSessions();
    }
  }, [user, sessions, fetchSessions]);

  const handleChangePage = useCallback(
    (_e: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
      fetchSessions({ offset: page * pagination.limit });
    },
    [pagination, fetchSessions]
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const rows = event.target?.value;
    if (rows) {
      fetchSessions({ limit: parseInt(rows, 10), offset: 0 });
    }
  };

  return (
    <Page title="Список трансляций | Autosport Live">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Текстовые трансляции
          </Typography>
          {/*
          <Button
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            Новая трансляция
          </Button>
          */}
        </Stack>

        <Card>
          <Scrollbar sx={{ overflowX: 'auto' }}>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={40}>ID</TableCell>
                    <TableCell>Наименование</TableCell>
                    <TableCell width={200}>Дата</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sessions.length > 0 &&
                    sessions.map((session) => (
                      <TableRow
                        key={session.nid}
                        hover={true}
                        onClick={() => {
                          navigate(`/dashboard/live/view/${session.nid}`);
                        }}
                      >
                        <TableCell>{session.nid}</TableCell>
                        <TableCell>{session.title}</TableCell>
                        <TableCell>{new Date(session.created).toLocaleString()}</TableCell>
                      </TableRow>
                    ))}
                  {sessions.length === 0 && (
                    <>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={pagination.total ?? 0}
            rowsPerPage={pagination.limit ?? 0}
            page={(pagination.offset ?? 0) / (pagination.limit ?? 1)}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          />
        </Card>
      </Container>

      <Backdrop
        open={loading}
        sx={{ background: alpha('#fff', 0.4), zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress size={48} variant={'indeterminate'} color={'primary'} />
      </Backdrop>
    </Page>
  );
};

export default LiveList;
