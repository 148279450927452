import { Helmet } from 'react-helmet-async';
import { FC, forwardRef } from 'react';
// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------
interface PageProps {
  children: any;
  title: string;
}

const Page: FC<PageProps & BoxProps> = forwardRef(({ children, title = '', ...other }, ref) => (
  <Box ref={ref} {...other}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </Box>
));

export default Page;
