import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Container,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import Page from '../../components/Page';
import { useAuth } from '../../hooks/useAuth';
import { LiveSession } from './LiveList';
import { alpha } from '@mui/material/styles';
import Scrollbar from '../../components/Scrollbar';
import './live.css';
import LiveMessage from './LiveMessage';
import { useCentrifuge } from '../../hooks/useCentrifuge';
import Centrifuge from 'centrifuge';
import LiveMessageEditor from './LiveMessageEditor';

declare const window: any;

export interface LiveSessionMessage {
  id?: number;
  messageId?: string;
  nid?: number;
  flag?: string | null;
  created?: number;
  source?: string;
  message: string;
}

type LiveMessageCommand = 'new' | 'edit' | 'delete';

const LiveView = () => {
  const { nid } = useParams();
  const { user, Api } = useAuth();
  const { centrifuge, connected } = useCentrifuge();
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState<LiveSession>();
  const [messages, setMessages] = useState<LiveSessionMessage[]>([]);
  const subscription = useRef<Centrifuge.Subscription | undefined>(undefined);
  const scrollRef = useRef<any>(undefined);

  const [edited, setEdited] = useState<LiveSessionMessage>({
    id: undefined,
    flag: null,
    nid: parseInt(nid ?? '', 10),
    message: '',
    source: ''
  });

  useEffect(() => {
    if (nid && !session) {
      setLoading(true);
      Api?.get(`/api/v1/sessions/${nid}`)
        .then((response) => {
          const { node, messages: nodeMessages } = response.data;
          setSession(node);
          setMessages(nodeMessages);
          setTimeout(() => {
            if (window.twttr) {
              window.twttr.widgets.load();
            }
          }, 500);
        })
        .finally(() => setLoading(false));
    }
  }, [user, Api, nid, session]);

  useEffect(() => {
    if (centrifuge?.isConnected()) {
      // console.log('cent connected');
      subscription.current = centrifuge?.subscribe('public:' + nid, {
        publish: function (message) {
          const newMessage: LiveSessionMessage = message.data.message;
          const newMessageCommand: LiveMessageCommand = message.data.command;
          if (newMessageCommand === 'new') {
            setMessages((prevState) => [newMessage, ...prevState]);
            scrollRef.current?.scroll(0, 0);
            if (window.twttr) {
              setTimeout(() => window.twttr.widgets.load(), 1000);
            }
          } else if (newMessageCommand === 'edit') {
            setMessages((prev) => {
              const _messages = [...prev];
              const index = _messages.findIndex((m) => m.id === newMessage.id);
              if (index !== -1) {
                _messages[index] = { ..._messages[index], ...newMessage };
              }
              return _messages;
            });
            if (window.twttr) {
              setTimeout(() => window.twttr.widgets.load(), 1000);
            }
          } else if (newMessageCommand === 'delete') {
            setMessages((prev) => {
              const _messages = [...prev];
              const index = _messages.findIndex((m) => m.id === newMessage.id);
              if (index !== -1) {
                _messages.splice(index, 1);
              }
              return _messages;
            });
            if (window.twttr) {
              window.twttr.widgets.load();
            }
          }
        }
        // subscribe: function (context) {
        //   // See below description of subscribe callback context format
        //   // console.log('subscribe', context);
        // },
        // error: function (errContext) {
        //   // See below description of subscribe error callback context format
        //   // console.log('error', errContext);
        // },
        // unsubscribe: function (context) {
        //   // See below description of unsubscribe event callback context format
        //   // console.log('unsubscribe', context);
        // }
      });
    } else {
      subscription.current?.unsubscribe();
    }
    return () => {
      if (subscription.current) {
        subscription.current?.unsubscribe();
      }
    };
  }, [connected, centrifuge, nid]);

  const handleSaveMessage = useCallback(
    (message: Partial<LiveSessionMessage>) => {
      setLoading(true);
      const isEdit = Boolean(message.id);
      Api?.request({
        url: `/api/v1/sessions/${nid}` + (isEdit ? '/' + message.id : ''),
        data: message,
        method: isEdit ? 'PUT' : 'POST'
      })
        .then(() => {
          setEdited({ flag: undefined, source: '', message: '' });
        })
        .finally(() => setLoading(false));
    },
    [nid, Api]
  );

  const handleMessageEdit = useCallback(
    (id: number) => {
      if (id) {
        setLoading(true);

        Api?.get(`/api/v1/sessions/${nid}/${id}`)
          .then((response) => {
            const message: LiveSessionMessage = response.data;
            setEdited(message);
          })
          .finally(() => setLoading(false));
      }
    },
    [nid, Api]
  );

  const handleMessageDelete = (id: number) => {
    if (id) {
      setLoading(true);
      Api?.delete(`/api/v1/sessions/${nid}/${id}`).finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    const twitterScript = document.getElementById('twttr-script');
    if (!twitterScript) {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://platform.twitter.com/widgets.js';
      script.id = 'twttr-script';
      script.onload = () => {
        if (window.twttr) {
          window.twttr.widgets?.load();
        }
      };
      document.head.appendChild(script);
    }
  }, []);

  return (
    <Page
      title={`${session?.title ?? 'Текстовая трансляция'} | Autosport Live`}
      sx={{ flexGrow: 1, display: 'flex' }}
    >
      <Container
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column'
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {session?.title ?? <Skeleton width={300} />}
          </Typography>
        </Stack>

        <Card sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              position: 'relative',
              minHeight: 300
            }}
          >
            <Box
              sx={{
                display: 'block',
                position: 'absolute',
                left: 0,
                top: 0,
                height: '100%',
                width: '100%',
                paddingTop: 3,
                paddingBottom: 3,
                paddingLeft: 1,
                paddingRight: 1
              }}
            >
              <Scrollbar
                sx={{
                  paddingLeft: 2,
                  paddingRight: 2
                }}
                scrollableNodeProps={{ ref: scrollRef }}
              >
                <Box>
                  {messages.map((message) => (
                    <LiveMessage
                      message={message}
                      key={`lm-${message.id}-${message.created}`}
                      onEdit={handleMessageEdit}
                      onDelete={handleMessageDelete}
                    />
                  ))}
                </Box>
              </Scrollbar>
            </Box>
          </Box>
          <Box
            sx={{
              minHeight: 200,
              padding: 3,
              borderTop: '1px solid rgba(241, 243, 244, 1)',
              backgroundColor: '#fafafa'
            }}
          >
            <LiveMessageEditor
              message={edited}
              onSend={handleSaveMessage}
              onCancel={() => setEdited({ flag: undefined, source: '', message: '' })}
              api={Api}
            />
          </Box>
        </Card>
      </Container>

      <Backdrop
        open={loading}
        sx={{ background: alpha('#fff', 0.4), zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress size={48} variant={'indeterminate'} color={'primary'} />
      </Backdrop>
    </Page>
  );
};

export default LiveView;
