import React, { useContext, createContext, FC, useEffect, useRef } from 'react';
import axios, { AxiosInstance } from 'axios';
import { useLocalStorageState } from 'ahooks';

interface AuthContext {
  user: unknown;
  login?: (username: string, password: string, callback?: () => {}) => Promise<any>;
  logout?: () => void;
  fetchUser?: () => Promise<any>;
  Api?: AxiosInstance;
}

const authContext = createContext<AuthContext>({ user: undefined, login: undefined });

export const AuthProvider: FC = ({ children }) => {
  const auth = useAuthProvider();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

function useAuthProvider() {
  const [token, setToken] = useLocalStorageState('app:token', {
    serializer: (v) => v,
    deserializer: (v) => v
  });

  const [user, setUser] = useLocalStorageState('app:user');

  const Api = useRef(
    axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL
    })
  );

  Api.current.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        setUser(undefined);
        setToken(undefined);
      }
      return error;
    }
  );

  if (token && user) {
    console.info('Set token for axios');
    Api.current.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  const fetchUser = () => {
    return Api.current
      .get('/api/me')
      .then((response) => {
        setUser(response.data);
        return response.data;
      })
      .catch((error) => {
        setUser(undefined);
        throw error.response?.data;
      });
  };

  const login = (username: string, password: string, callback?: () => void) => {
    return Api.current
      .post(
        '/api/token',
        {
          username,
          password
        },
        { headers: { Authorization: false } }
      )
      .then((response) => {
        Api.current.defaults.headers.common['Authorization'] = 'Bearer ' + response.data?.token;
        setToken(response.data?.token);
        fetchUser().then(() => {
          if (callback) callback();
        });
        return response.data;
      })
      .catch((error) => {
        setToken(undefined);
        throw error.response?.data;
      });
  };

  const logout = () => {
    setUser(undefined);
    setToken(undefined);
  };
  //

  useEffect(() => {
    if (token) {
      Api.current.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    } else {
      delete Api.current.defaults.headers.common['Authorization'];
      setUser(undefined);
      setToken(undefined);
    }
  }, [token, setToken, setUser]);

  //
  // Return the user object and auth methods
  return {
    user,
    fetchUser,
    login,
    logout,
    Api: Api.current
  };
}
