import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { LiveSessionMessage } from './LiveView';
import {
  Box,
  IconButton,
  SvgIcon,
  SvgIconProps,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import sendIcon from '@iconify/icons-eva/paper-plane-outline';
import arrowUp from '@iconify/icons-eva/arrowhead-up-outline';
import arrowDown from '@iconify/icons-eva/arrowhead-down-outline';
import messageIcon from '@iconify/icons-eva/message-circle-outline';
import headphonesIcon from '@iconify/icons-eva/headphones-outline';
import alertIcon from '@iconify/icons-eva/alert-triangle-outline';
import infoIcon from '@iconify/icons-eva/info-outline';
import cancelIcon from '@iconify/icons-eva/close-circle-outline';
import listIcon from '@iconify/icons-eva/list-outline';
import imageIcon from '@iconify/icons-eva/image-outline';
import linkIcon from '@iconify/icons-eva/link-2-outline';

import { Icon } from '@iconify/react';
import clsx from 'clsx';
import TableParser from './TableParser';
import { AxiosInstance } from 'axios';
import AddImageDialog from './AddImageDialog';
import AddLinkDialog from './AddLinkDialog';

interface LiveMessageEditorProps {
  message: LiveSessionMessage;
  onSend: (message: Partial<LiveSessionMessage>) => void;
  onCancel: () => void;
  api?: AxiosInstance;
}

const LiveMessageEditor: FC<LiveMessageEditorProps> = ({ message, onSend, onCancel, api }) => {
  const [text, setText] = useState('');
  const [dialog, setEditorDialog] = useState<string | undefined>('');
  const [flag, setFlag] = useState<string | null | undefined>(null);
  const inputRef = useRef<any>();

  useEffect(() => {
    if (message) {
      setText(message?.source ?? '');
      setFlag(message?.flag);
    }
  }, [message]);

  const handleFlagChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, value: any) => {
      if (value === flag) {
        setFlag(null);
      } else {
        setFlag(value);
      }
      if (inputRef.current) {
        inputRef.current?.focus();
      }
    },
    [flag]
  );

  const handleSend = useCallback(() => {
    onSend({ ...message, source: text, flag });
  }, [flag, text, message, onSend]);

  const insertTextAtCursor = useCallback(
    (textToInsert) => {
      let cursorPosition = inputRef.current.selectionStart;
      let textBeforeCursorPosition = text.substring(0, cursorPosition);
      let textAfterCursorPosition = text.substring(cursorPosition, text.length);
      setText(textBeforeCursorPosition + textToInsert + textAfterCursorPosition);
    },
    [text]
  );

  const handleImagePaste = useCallback(
    (url, alt) => {
      insertTextAtCursor(`![${alt ?? ''}](${url})`);
      setEditorDialog(undefined);
    },
    [insertTextAtCursor]
  );

  const handleLinkPaste = useCallback(
    (url, text) => {
      insertTextAtCursor(`[${text ?? ''}](${url})`);
      setEditorDialog(undefined);
    },
    [insertTextAtCursor]
  );

  const handlePasteTable = useCallback(
    (table) => {
      insertTextAtCursor(table);
      setEditorDialog(undefined);
    },
    [insertTextAtCursor]
  );

  return (
    <div>
      <Box sx={{ marginBottom: 1 }}>
        <ToggleButtonGroup
          exclusive={true}
          size={'small'}
          value={flag}
          onChange={handleFlagChange}
          color={'primary'}
          sx={{ mr: 1 }}
        >
          <ToggleButton value={'yellow'}>
            <FlagIcon color={'warning'} />
          </ToggleButton>
          <ToggleButton value={'green'}>
            <FlagIcon color={'success'} />
          </ToggleButton>
          <ToggleButton value={'red'}>
            <FlagIcon color={'error'} />
          </ToggleButton>
          <ToggleButton value={'finish'}>
            <FinishIcon />
          </ToggleButton>
          <ToggleButton value={'quote'}>
            <Icon icon={messageIcon} />
          </ToggleButton>
          <ToggleButton value={'radio'}>
            <Icon icon={headphonesIcon} />
          </ToggleButton>
          <ToggleButton value={'alert'}>
            <Icon icon={alertIcon} />
          </ToggleButton>
          <ToggleButton value={'info'}>
            <Icon icon={infoIcon} />
          </ToggleButton>
          <ToggleButton value={'pit-in'}>
            <Icon icon={arrowUp} style={{ marginRight: 4 }} />
          </ToggleButton>
          <ToggleButton value={'pit-out'}>
            <Icon icon={arrowDown} style={{ marginRight: 4 }} />
          </ToggleButton>
        </ToggleButtonGroup>

        <ToggleButtonGroup exclusive={true} onChange={(_event, value) => setEditorDialog(value)}>
          <ToggleButton value={'insertLink'} size={'small'} color={'primary'}>
            <Icon icon={linkIcon} />
          </ToggleButton>
          <ToggleButton value={'insertImage'} size={'small'} color={'primary'}>
            <Icon icon={imageIcon} />
          </ToggleButton>
          <ToggleButton value={'parseTable'} size={'small'} color={'primary'}>
            <Icon icon={listIcon} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box
        sx={{
          display: 'block',
          position: 'relative',
          '& .editor-controls': {
            position: 'absolute',
            bottom: 5,
            right: 5
          },
          '& textarea': {
            fontFamily: '"Roboto Mono", monospace'
          }
        }}
      >
        <TextField
          inputRef={inputRef}
          fullWidth={true}
          value={text}
          onChange={(e) => setText(e.target.value)}
          multiline={true}
          // rows={4}
          minRows={4}
          maxRows={10}
          sx={{ background: '#fff' }}
          onKeyDown={(event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'Enter') {
              handleSend();
            }
          }}
          className={clsx(flag && `editor-input-icon editor-input-${flag}`)}
        />

        {/*
        <Box
          className={clsx(
            flag && `editor-input-icon editor-input-${flag}`,
            editorFocused && 'focused'
          )}
          sx={{
            overflowY: 'auto',
            maxHeight: 300,
            minHeight: 150,
            backgroundColor: '#fff',
            borderWidth: 1,
            borderColor: 'rgba(145, 158, 171, 0.32)',
            borderRadius: 1,
            padding: 1.5,
            '&.focused': {
              borderColor: (theme) => theme.palette.primary.main
            },
            '&.editor-input-icon': {
              paddingLeft: 8
            }
          }}
        >
          <Slate editor={editor} value={value} onChange={(newValue) => setValue(newValue)}>
            <Editable
              onFocus={() => setEditorFocused(true)}
              onBlur={() => setEditorFocused(false)}
            />
          </Slate>
        </Box>
        */}
        <div className={'editor-controls'}>
          <IconButton color={'primary'} onClick={handleSend} size={'small'}>
            <Icon icon={sendIcon} width={20} height={20} />
          </IconButton>
          {message?.id && (
            <IconButton color={'default'} onClick={onCancel} size={'small'}>
              <Icon icon={cancelIcon} width={20} height={20} />
            </IconButton>
          )}
        </div>
      </Box>
      <TableParser
        onSave={handlePasteTable}
        api={api}
        open={'parseTable' === dialog}
        onClose={() => setEditorDialog(undefined)}
      />
      <AddImageDialog
        open={'insertImage' === dialog}
        onSave={handleImagePaste}
        onClose={() => setEditorDialog(undefined)}
      />
      <AddLinkDialog
        open={'insertLink' === dialog}
        onSave={handleLinkPaste}
        onClose={() => setEditorDialog(undefined)}
      />
    </div>
  );
};

export default LiveMessageEditor;

const FlagIcon: FC<SvgIconProps> = (props: Partial<SvgIconProps>) => (
  <SvgIcon viewBox={'0 0 24 24'} fontSize={'inherit'} {...props}>
    <path fill="currentColor" d="M19 4H6V2H4v18H3v2h4v-2H6v-5h13a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z" />
  </SvgIcon>
);

const FinishIcon: FC<SvgIconProps> = (props: Partial<SvgIconProps>) => (
  <SvgIcon viewBox={'0 0 32 32'} fontSize={'inherit'} {...props}>
    <path
      fill="currentColor"
      d="M5 4v24h2v-8h20V4H5zm2 2h3v3h3V6h3v3h3V6h3v3h3v3h-3v3h3v3h-3v-3h-3v3h-3v-3h-3v3h-3v-3H7v-3h3V9H7V6zm3 6v3h3v-3h-3zm3 0h3V9h-3v3zm3 0v3h3v-3h-3zm3 0h3V9h-3v3z"
    />
  </SvgIcon>
);
