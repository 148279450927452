import { createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DashboardSidebar from './DashboardSidebar';
import { MHidden } from '../../components/@material-extend';
import { Box, IconButton } from '@mui/material';
import { Icon } from '@iconify/react/dist/iconify';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';

// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  flexDirection: 'column'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  minHeight: '100%',
  // paddingTop: APP_BAR_MOBILE + 24,
  paddingTop: 24,
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.up('lg')]: {
    // paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export const SidebarContext = createContext({
  isOpen: false,
  isCollapsed: false,
  setOpen: () => {},
  setCollapsed: () => {}
});

const DashboardRoot = () => {
  const { isOpen, setOpen, isCollapsed, setCollapsed } = useContext(SidebarContext);
  // const { Api, user } = useAuth();
  //
  // const centrifuge = useRef(undefined);
  //
  // useEffect(() => {
  //   if (!centrifuge.current) {
  //     centrifuge.current = new Centrifuge('ws://localhost:8000/connection/websocket');
  //
  //     const callbacks = {
  //       publish: function (message) {
  //         // See below description of message format
  //         console.log('publish', message);
  //       },
  //       join: function (message) {
  //         // See below description of join message format
  //         console.log('join', message);
  //       },
  //       leave: function (message) {
  //         // See below description of leave message format
  //         console.log('leave', message);
  //       },
  //       subscribe: function (context) {
  //         // See below description of subscribe callback context format
  //         console.log('subscribe', context);
  //       },
  //       error: function (errContext) {
  //         // See below description of subscribe error callback context format
  //         console.log('error', errContext);
  //       },
  //       unsubscribe: function (context) {
  //         // See below description of unsubscribe event callback context format
  //         console.log('unsubscribe', context);
  //       }
  //     };
  //
  //     centrifuge.current.on('connect', function (context) {
  //       // now client connected to Centrifugo and authorized
  //       console.log('Centrifuge connected', context);
  //       centrifuge.current.subscribe('public:news', callbacks);
  //     });
  //
  //     centrifuge.current.on('disconnect', function (context) {
  //       console.log('Centrifuge disconnected', context);
  //     });
  //   }
  //
  //   return () => {
  //     if (centrifuge.current) {
  //       centrifuge.current?.disconnect();
  //     }
  //   };
  // }, []);
  //
  // useEffect(() => {
  //   if (user && !centrifuge.current.isConnected()) {
  //     setTimeout(
  //       () =>
  //         Api.get('/api/centrifugo/user').then((response) => {
  //           console.log(response.data);
  //           centrifuge.current.setToken(response.data.token);
  //           centrifuge.current.connect();
  //         }),
  //       1000
  //     );
  //   }
  // }, [user, Api]);

  return (
    <RootStyle>
      {/* <DashboardNavbar onOpenSidebar={() => setOpen(true)} /> */}
      <MHidden width="lgUp">
        <Box sx={{ height: 24, display: 'flex' }}>
          <Box sx={{ flexGrow: 1 }} />
          <div>
            <IconButton onClick={() => setOpen(true)} sx={{ mr: 1, color: 'text.primary' }}>
              <Icon icon={menu2Fill} />
            </IconButton>
          </div>
        </Box>
      </MHidden>
      <DashboardSidebar
        isOpenSidebar={isOpen}
        onCloseSidebar={() => setOpen(false)}
        isCollapsed={isCollapsed}
        onCollapse={() => setCollapsed(!isCollapsed)}
      />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
};

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  return (
    <SidebarContext.Provider
      value={{ isOpen: open, isCollapsed: collapsed, setOpen, setCollapsed }}
    >
      <DashboardRoot />
    </SidebarContext.Provider>
  );
}
